import { createStore } from "https://framer.com/m/framer/store.js@^1.0.0"
import { randomColor } from "https://framer.com/m/framer/utils.js@^0.9.0"
import { useState } from "react"

const useStore = createStore({
    background: "#0099FF",
    inputValue: "", // Agregar el estado del input
    setInputValue: (value) => {}, // Función para actualizar el input
})

export function withRotate(Component) {
    return (props) => {
        return (
            <Component
                {...props}
                animate={{ rotate: 90 }}
                transition={{ duration: 2 }}
            />
        )
    }
}

export function withHover(Component) {
    return (props) => {
        return <Component {...props} whileHover={{ scale: 1.05 }} />
    }
}

export function withRandomColor(Component) {
    return (props) => {
        const [store, setStore] = useStore()
        const { inputValue, setInputValue } = store

        const handleChange = (event) => {
            setInputValue(event.target.value) // Actualizar el valor del input en el store
        }

        const handleReset = () => {
            setInputValue("") // Resetea el valor del input
        }

        return (
            <Component
                {...props}
                animate={{
                    background: store.background,
                }}
                onClick={() => {
                    setStore({ background: randomColor() })
                }}
            >
                {/* Renderizar el input y el botón para resetear */}
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder="Introduce texto"
                />
                <button onClick={handleReset}>Resetear Input</button>
            </Component>
        )
    }
}
